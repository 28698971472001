<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card elevation="1" :disabled="loading">
    <template v-if="showPlayer">
      <v-expand-transition>
        <record-player
          @closed="closedPlayer"
          v-if="showPlayer || (recordList.length > 0 && showPlayer)"
          :file="file"
        />
      </v-expand-transition>

      <v-divider />
    </template>
    <v-toolbar flat>
      <v-row no-gutters class="mt-4">
        <v-col>
          <v-text-field
            :rules="[rules.phone]"
            prepend-inner-icon="mdi-numeric-positive-1"
            outlined
            v-model="filternumber"
            dense
            clearable
            label="Search by Number"
            :disabled="filterpatient != null || filteremployee != null"
            @click:clear="clearFilters"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-autocomplete
            class="mx-1"
            :disabled="
              filteremployee != null ||
              (filternumber != null && filternumber != '')
            "
            v-model="filterpatient"
            :label="'Search by ' + getpatientN + 's'"
            :items="patients"
            :loading="loadingEmployees"
            :filter="
              (item, queryText) => {
                return item.fullname
                  .toLowerCase()
                  .includes(queryText.toLowerCase());
              }
            "
            item-text="fullname"
            item-value="homePhone"
            autocomplete="off"
            clearable
            hide-details
            outlined
            @click:clear="clearFilters"
            dense
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.fullname"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-if="data.item.homePhone"
                  class="text-capitalize"
                  v-html="data.item.homePhone"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <template v-if="isSuper || isAdmin || isSupervisor">
          <v-col>
            <v-autocomplete
              v-model="filteremployee"
              class="ml-2"
              label="Search by Employees"
              :items="workersf"
              :loading="loadingEmployees"
              :disabled="
                filterpatient != null ||
                (filternumber != null && filternumber != '')
              "
              :filter="
                (item, queryText) => {
                  return item.fullname
                    .toLowerCase()
                    .includes(queryText.toLowerCase());
                }
              "
              item-text="fullname"
              item-value="phone"
              autocomplete="off"
              clearable
              hide-details
              outlined
              @click:clear="clearFilters"
              dense
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.fullname"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-if="data.item.ocupation"
                    class="text-capitalize"
                    v-html="data.item.ocupation.name"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="data.item.phone"
                    class="text-capitalize"
                    v-html="data.item.phone"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </template>
        <v-col class="">
          <ma-date-picker v-model="dateFrom" label="From" past />
        </v-col>
        <v-col class="mx-2">
          <ma-date-picker v-model="dateTo" label="To" past />
        </v-col>
        <template v-if="validClean">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="ml-2"
                elevation="0"
                small
                rounded
                depressed
                fab
                v-on="on"
                v-bind="attrs"
                @click="_clearFilters"
              >
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </template>
            <span>Clear filters</span>
          </v-tooltip>
        </template>
      </v-row>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        text
        @click="getCalls"
      >
        <v-icon>mdi-sync</v-icon>
        {{ $t("labelUpdate") }}
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-data-table
      :headers="headers"
      :items="recordList"
      :expanded.sync="expanded"
      :footer-props="footerProps"
      :options.sync="options"
      :server-items-length="totalrecord"
      item-key="index"
      :loading="loading"
    >
      {{/* From agent */}}
      <template v-slot:[`item.from`]="{ item }">
        <div class="phone-number">
          <v-icon color="green" class="mr-1"> mdi-phone-outgoing </v-icon>
          {{ getFrom(item) }}
        </div>
      </template>
      {{/* To agent */}}
      <template v-slot:[`item.to`]="{ item }">
        <div class="phone-number">
          <v-icon color="green" class="mr-1">mdi-phone-incoming</v-icon>
          {{ getTo(item) }}
        </div>
      </template>
      <!-- <template v-slot:[`item.status`]="{ item }">
        <span
          v-if="item.status"
          class="text-caption d-inline-flex align-center"
        >
          <v-icon class="mr-1" :color="statusColor(item.status)">
            {{ statusIcon(item.status) }}
          </v-icon>
          {{ item.status }}
        </span>
      </template> -->
      {{/* Duration */}}
      <template v-slot:[`item.duration`]="{ item }">
        <div class="text-body-2">{{ prettyTime(item.duration) }}</div>
      </template>
      {{/* Origin */}}
      <template v-slot:[`item.origin`]="{ item }">
        <div class="text-body-2">{{ _origin(item) }}</div>
      </template>
      {{/* Name */}}
      <template v-slot:[`item.name`]="{ item }">
        <div class="text-body-2">{{ _name(item) }}</div>
      </template>

      <template v-slot:[`item.toname`]="{ item }">
        <div class="text-body-2">{{ getNameTo(item) }}</div>
      </template>
      {{/* Date */}}
      <template v-slot:[`item.createAt`]="{ item }">
        <div class="text-body-2">{{ formatDate(item.createAt) }}</div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        {{/* Play record */}}

        <template v-if="item.recordUrl != null">
          <v-btn
            :class="{ 'nt-notification-primary': item.recordUrl == file }"
            :disabled="item.recordUrl == null || loadingaudio"
            color="primary"
            :loading="loadingaudio && currentAudio == item.recordUrl"
            icon
            @click="playRecord(item.recordUrl)"
          >
            <v-icon>mdi-play-circle-outline</v-icon>
          </v-btn>
        </template>

        {{/* Remove record */}}
        <template v-if="admin">
          <v-btn color="red" icon @click="setRecordToRemove(item)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </template>

      <not-records slot="no-data" />
    </v-data-table>

    {{/* Confirm remove */}}
    <v-dialog
      v-model="dialogConfirmRemove"
      max-width="280"
      content-class="rounded-xl"
      :persistent="loading"
    >
      <v-card>
        <v-card-title class="text-h6 font-weight-regular rounded-xl">
          {{ $t("dialogTitle") }}
        </v-card-title>
        <v-layout class="pa-4" column>
          <div class="text-body-1 text-truncate d-flex mb-2">
            <v-icon color="green" class="mr-1">mdi-phone-outgoing</v-icon>
            <span class="dosis">{{ currentRecord.from }}</span>
          </div>
          <div class="text-body-1 text-truncate d-flex">
            <v-icon color="green" class="mr-1">mdi-phone-incoming</v-icon>
            <span class="dosis">{{ cleanPhone(currentRecord.to) }}</span>
          </div>
        </v-layout>
        <v-divider />
        <v-card-actions class="justify-end">
          <v-btn
            color="success"
            :disabled="loading"
            @click="dialogConfirmRemove = false"
            text
          >
            {{ $t("labelCancel") }}
          </v-btn>
          <v-btn color="red" :loading="loading" @click="remove" text>
            {{ $t("labelRemove") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/**
 * Show all recorded calls
 */
import Vue from "vue";
import RecordPlayer from "./RecordPlayer.vue";
import NotRecords from "@/components/NotRecords.vue";
import rules from "@/components/account/rules";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { CallList } from "@/mixins";
import RecordedCall from "@/models/RecordedCall";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";
import { notifyInfo } from "@/components/Notification";
import axios from "axios";
import { apiBaseUrl } from "@/enviorment";

export default Vue.extend({
  name: "recorded-calls",
  mixins: [CallList],
  components: {
    RecordPlayer,
    NotRecords,
    MaDatePicker,
  },
  data() {
    return {
      rules: {
        email: rules.email,
        required: rules.required,
        phone: rules.isPhone,
      },
      footerProps: { "items-per-page-options": [15, 50, 100] },
      options: {},
      filternumber: null,
      filterpatient: null,
      filteremployee: null,
      currentRecord: {
        uuid: "66efaa8b-7d8c-4a44-8d94-24aede53f2f7",
        from: "+111111111",
        to: "+122222222",
      },
      dialogConfirmRemove: false,
      filterRange: "year",
      reqbody: {
        range: {
          limit: 15,
          offset: 0,
        },
        record: true,
        dateRange: {
          date1: "",
          date2: "",
        },
        phoneNumber: null,
      },
      dateFrom: "",
      dateTo: "",
      loadingaudio: false,
      currentAudio: null,
      expanded: [],
      file: "",
      admin: false,
      showPlayer: false,
      headers: [
        {
          text: this.$t("origin"),
          align: "start",
          sortable: false,
          value: "origin",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("crmCallCenter.from"),
          align: "start",
          sortable: false,
          value: "from",
        },
        {
          text: this.$t("Name"),
          align: "start",
          sortable: false,
          value: "toname",
        },
        {
          text: this.$t("crmCallCenter.to"),
          align: "start",
          sortable: false,
          value: "to",
        },
        {
          text: this.$t("crmCallCenter.duration"),
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("labelDate"),
          align: "center",
          sortable: false,
          value: "createAt",
        },
        { text: "", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  mounted() {
    if (this.isAdmin || this.isSuper) {
      this.admin = true;
      this.actGetEmployees();
    } else {
      this.admin = false;
    }
    this.actListPatientsFilter();
  },
  computed: {
    ...mapState("crmCallCenterModule", [
      "recordList",
      "totalrecord",
      "requestC",
      "filter",
    ]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState(["userContacts"]),
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getPhone",
      "getUser",
      "getName",
      "getpatientN",
      "getMethodCalls",
    ]),

    workersf() {
      const w = this.employees.filter((e) => e.phone != null && e.phone != "");
      return w;
    },
    validClean() {
      if (
        (this.dateFrom != null &&
          this.dateFrom != undefined &&
          this.dateFrom != "") ||
        (this.dateTo != null && this.dateTo != undefined && this.dateTo != "")
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getCalls();
      }
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.filterRange = "custom";
        this.getCalls();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.filterRange = "custom";
        this.getCalls();
      }
    },
    filternumber(val) {
      if (val != null) {
        this.filterpatient = null;
        this.filteremployee = null;
        if (val.length == 10) {
          this.reqbody.phoneNumber = "+1" + val;
          this.getCalls();
        } else if (val.length == 0) {
          this.reqbody.phoneNumber = null;
          this.getCalls();
        }
      }
    },
    filterpatient(val) {
      if (val != null) {
        this.filternumber = null;
        this.filteremployee = null;
        this.reqbody.phoneNumber = val;
        this.getCalls();
      }
    },
    filteremployee(val) {
      if (val != null) {
        this.filterpatient = null;
        this.filternumber = null;
        this.reqbody.phoneNumber = val;
        this.getCalls();
      }
    },
  },

  methods: {
    ...mapActions("crmCallCenterModule", ["actGetCallsRec"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmMedicFormModule", ["actListPatientsFilter"]),
    ...mapMutations("crmCallCenterModule", [
      "mutRequest",
      "mutFilter",
      "mutView",
    ]),
    ...mapMutations(["mutReqRange"]),

    getNameTo(item) {
      if (item.to == `client:${item.employee_username}`) {
        return item.employee_fullname;
      }
      if (item.to == item.employee_phone) {
        return item.employee_fullname;
      }
      if (item.patient_phone == item.to) {
        return item.patient_name;
      }
      if (item.l_phone == item.to) {
        return item.l_name;
      }
      return "-";
    },

    closedPlayer() {
      this.file = "";
      this.showPlayer = false;
    },
    async playRecord(file) {
      if (this.getMethodCalls == "twilio") {
        this.file = file;
      } else {
        const token = this.$store.state.accessToken;
        let auth =
          token != undefined && token != null && token != ""
            ? {
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              }
            : {
                Accept: "audio/mpeg",
              };
        this.loadingaudio = true;
        this.currentAudio = file;
        const url = apiBaseUrl + "/zoom/getZoomAudio?media_url=";
        try {
          const response = await axios({
            method: "get",
            url: url + file,
            responseType: "blob",
            headers: auth,
          });

          const buffer = new Blob([response.data]);

          let file_path = URL.createObjectURL(buffer);
          this.file = file_path;
          this.loadingaudio = false;
          this.currentAudio = null;
        } catch (error) {
          this.loadingaudio = false;
          this.currentAudio = null;
        }
      }
      this.showPlayer = true;
    },

    getFrom(call) {
      if (call.from == null || call.from == undefined) {
        return "-";
      }
      if (call.from == this.getPhone || call.from == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return this.cleanPhone(call.from, call);
      }
    },
    getTo(call) {
      if (call.to == null || call.to == undefined) {
        return "-";
      }
      if (call.to == this.getPhone || call.to == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return this.cleanPhone(call.to, call);
      }
    },

    getCalls() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 15;
      this.reqbody.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      if (this.filterRange == "year") {
        this.reqbody.dateRange = {
          date1: moment().startOf("year").utc().toISOString(),
          date2: moment().utc().toISOString(),
        };
      } else {
        this.reqbody.dateRange = {
          date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
          date2: moment(this.dateTo).endOf("day").utc().toISOString(),
        };
      }
      this.actGetCallsRec(this.reqbody);
    },
    setRecordToRemove(record) {
      this.currentRecord = record;
      this.dialogConfirmRemove = true;
      this.showPlayer = false;
    },
    remove() {
      this.actRemoveRecordedCall(this.currentRecord.uuid).then(() => {
        this.dialogConfirmRemove = false;
        this.getCalls();
        const en = this.$i18n.locale == "en";
        const bodyEn = "Call deleted successfully";
        const bodyEs = "Llamada eliminada con éxito";

        notifyInfo(en ? bodyEn : bodyEs);
      });
    },

    _clearFilters() {
      this.dateFrom = "";
      this.dateTo = "";
      this.filterRange = "year";
      this.clearFilters();
    },
    clearFilters() {
      this.filternumber = null;
      this.filterpatient = null;
      this.filteremployee = null;
      this.reqbody.phoneNumber = null;
      this.getCalls();
    },

    _origin(item) {
      if (item.from == `client:${item.employee_username}`) {
        return "Employee";
      }

      if (item.from == item.employee_phone) {
        return "Employee";
      }
      if (item.patient_phone == item.from) {
        return "Patient";
      }
      if (item.l_phone == item.from) {
        return "Lead";
      }

      return "-";
    },
    _name(item) {
      if (item.from == `client:${item.employee_username}`) {
        return item.employee_fullname;
      }

      if (item.from == item.employee_phone) {
        return item.employee_fullname;
      }
      if (item.patient_phone == item.from) {
        return item.patient_name;
      }
      if (item.l_phone == item.from) {
        return item.l_name;
      }

      return "-";
    },
  },
});
</script>

<i18n>
{
  "en": {
    "dialogTitle": "Delete recording?",
    "origin":"Origin"
  },
  "es": {
    "dialogTitle": "¿Eliminar grabación?",
    "origin":"Origen"
  }
}
</i18n>
